<template>
  <div>

    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="4">
              <b-form-group
                label="Booking ID"
                label-for="mc-bookingId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Booking ID"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.booking_id"
                    class="booking"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Booking ID"
                    :clearable="false"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-group
                  label="Select Type"
                  label-for="mc-select-type"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.module_id"
                    :options="FlightExcludeEnum"
                    class="type"
                    placeholder="Select Type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Hotel Supplier ID"
                label-for="mc-supplier"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Hotel Supplier ID"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.hotel_supplier_id"
                    class="supplierId"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Hotel Supplier ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-show="manualBookingForm.module_id === 3"
              md="4"
            >
              <b-form-group
                label="Flight Supplier ID"
                label-for="mc-supplier"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Flight Supplier ID"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.flight_supplier_id"
                    class="supplierId"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Flight Supplier ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Booking Status"
                rules="required"
              >
                <b-form-group
                  label="Booking Status"
                  label-for="mc-booking-status"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.status"
                    :options="BookingStatusEnum"
                    class="type"
                    placeholder="Select Booking Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="User"
                rules="required"
              >
                <b-form-group
                  label="User"
                  label-for="mc-user"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.user_id"
                    :options="filterList"
                    class="type"
                    placeholder="Select User"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">

              <validation-provider
                #default="{ errors }"
                name="Checkin-Checkout date"
                rules="required"
              >
                <b-form-group
                  label="Select Checkin-Checkout Date"
                  label-for="mc-select-date"
                  :state="errors.length > 0 ? false:null"
                >
                  <date-picker
                    v-model="bookingDates"
                    type="date"
                    range
                    format="MM/DD/YYYY"
                    :open-date="new Date()"
                    :disabled-date="disabledRange"
                    placeholder="Select date range"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-show="manualBookingForm.module_id === 3"
              md="4"
            >
              <!-- <validation-provider
                #default="{ errors }"
                name="Origin"
                rules="required"
              > -->
              <b-form-group
                label="Origin"
                label-for="mc-origin"
              >
                <v-select
                  class="origin"
                  placeholder="Select Origin"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Destination"
                rules="required"
              >
                <b-form-group
                  label="Destination"
                  label-for="mc-destination"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.destination"
                    :options="DestinationList"
                    class="destination"
                    placeholder="Select Destination"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Currency"
                rules="required"
              >
                <b-form-group
                  label="Currency"
                  label-for="mc-currency"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.currency"
                    :options="currencyOptions"
                    class="currency"
                    placeholder="Select Currency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Whitelabel"
                rules="required"
              >
                <b-form-group
                  label="Whitelabel"
                  label-for="mc-whitelabel"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.whitelabel"
                    class="whitelabel"
                    :options="WhiteLabelEnum"
                    placeholder="Select Whitelabel"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Discount Code"
                label-for="mc-discount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Discount Code"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.discount_code"
                    class="discount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Discount Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Discount Amount"
                label-for="mc-discount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Discount Amount"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.discount_amount"
                    class="discount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Discount Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Payment Method"
                rules="required"
              >
                <b-form-group
                  label="Payment Method"
                  label-for="mc-payment"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.payment_method"
                    :options="PaymentTypeEnum"
                    class="payment"
                    placeholder="Select Payment Method"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Payment Type"
                rules="required"
              >
                <b-form-group
                  label="Payment Type"
                  label-for="mc-payment"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.payment_type"
                    :options="PaymentInstallmentType"
                    class="payment"
                    placeholder="Select Payment Type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Zipcode"
                label-for="mc-zipcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Zipcode"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.zip_code"
                    class="zipcode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Zipcode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="mc-country"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="manualBookingForm.country"
                    :options="countries"
                    class="country"
                    placeholder="Select Country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="City"
                label-for="mc-city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.city"
                    class="city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Address line 1"
                label-for="mc-address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.address"
                    class="address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Company Name"
                label-for="mc-company"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company name"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.company_name"
                    class="company"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="VAT Number"
                label-for="mc-vat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="VAT Number"
                  rules="required"
                >
                  <b-form-input
                    v-model="manualBookingForm.vat_number"
                    class="vat"
                    :state="errors.length > 0 ? false:null"
                    placeholder="VAT Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" class="demo-inline-spacing">
              <b-button
                :disabled="loading"
                variant="primary"
                type="submit"
                @click="createNewBooking"
              >
                Save Booking
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>

              <b-button
                variant="secondary"
                :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'booking-list':'dashboard'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-card-code>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import currencyService from '@/services/currency/currency.service'
import errorResponseHandler from '@/services/errorHandler'
import {
  resConditionCheck, PaymentTypeEnum, PaymentInstallmentType, BookingStatusEnum, tripTypeFilter, FlightExcludeEnum, WhiteLabelEnum, RoleEnum
} from '@/helpers/constant'
import checkLoginRole from '@/helpers/checkLoginRole'
import accessRightCheck from '@/helpers/accessRightCheck'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CountryService from '@/services/country/country.service'
import { required } from '@/@core/utils/validations/validations'
import BookingService from '@/services/booking/booking.service'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
    DatePicker,
    BButton
  },

  data() {
    return {
      currencyOptions: [],
      countries: [],
      countriesList: [],
      filterList: [],
      DestinationList: [],
      userList: [],
      checkLoginRole,
      checkAccess: {},
      manualBookingForm: {
        booking_id: `BON${Math.floor(10000 + Math.random() * 9000)}`,
        module_id: '',
        hotel_supplier_id: '',
        flight_supplier_id: 0,
        status: '',
        user_id: '',
        checkin_date: '',
        checkout_date: '',
        origin: 0,
        destination: '',
        currency: '',
        whitelabel: 1,
        discount_code: '',
        discount_amount: '',
        payment_method: '',
        payment_type: '',
        zip_code: '',
        country: '',
        city: '',
        address: '',
        company_name: '',
        vat_number: '',
      },
      required,
      loading: false,
      // Enums
      PaymentTypeEnum,
      PaymentInstallmentType,
      BookingStatusEnum,
      WhiteLabelEnum,
      FlightExcludeEnum,
      RoleEnum,
      tripTypeFilter,
      bookingDates: '',

    }
  },

  watch: {
    bookingDates: {
      handler(newValue) {
        this.manualBookingForm.checkin_date = newValue[0] ? moment(newValue[0]).format('YYYY-MM-DD') : ''
        this.manualBookingForm.checkout_date = newValue[1] ? moment(newValue[1]).format('YYYY-MM-DD') : ''
      }
    }
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    await this.getCurrencyDropDown()
    this.getCountryList()
    this.getFilterList()
    this.getHotelDestination()
  },

  methods: {
    async createNewBooking() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let countryObj = '';
          this.countriesList.forEach(item => {
            if (item.id === this.manualBookingForm.country) {
              countryObj = {
                id: item.id,
                code: item.iso2.trim(),
                name: item.country.replace(/[0-9]/g, '').replace(/[()]/g, '').replace(/[-]/g, ' ').trim()
              }
            }
          })
          const requestData = {
            booking_id: this.manualBookingForm.booking_id,
            module_id: this.manualBookingForm.module_id,
            hotel_supplier_id: this.manualBookingForm.hotel_supplier_id,
            flight_supplier_id: this.manualBookingForm.flight_supplier_id,
            status: this.manualBookingForm.status,
            user_id: this.manualBookingForm.user_id,
            checkin_date: this.manualBookingForm.checkin_date,
            checkout_date: this.manualBookingForm.checkout_date,
            origin: this.manualBookingForm.origin,
            destination: this.manualBookingForm.destination,
            // rooms: [{ adults: 2, children: [12] }],
            currency: this.manualBookingForm.currency,
            discount_code: this.manualBookingForm.discount_code,
            discount_amount: this.manualBookingForm.discount_amount,
            payment_method: this.manualBookingForm.payment_method,
            zip_code: this.manualBookingForm.zip_code,
            country: countryObj,
            city: this.manualBookingForm.city,
            address: this.manualBookingForm.address,
            company_name: this.manualBookingForm.company_name,
            vat_number: this.manualBookingForm.vat_number
          }

          BookingService.createNewBooking(requestData).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.loading = false
              // this.$router.push(`/booking/new-booking/booking-details/${res.data.quote_id}`)
              this.$router.push(`/booking/list`)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "X-CircleIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    },

    async getCurrencyDropDown() {
      try {
        const res = await currencyService.getCurrencyDropDown()
        if (resConditionCheck(res.status) && res.data.data) {
          this.currencyOptions = res.data.data.map(e => ({
            label: e.code,
            value: e.id
          }))
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    getCountryList() {
      CountryService.getCountryList()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.countriesList = res.data.data;
            this.countries = res.data.data.map(e => ({
              label: e.country,
              value: e.id
            }))
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },

    async getFilterList() {
      await BookingService.getAllUsers()
        .then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.filterList = res.data.data.map(e => ({
              label: e.email,
              value: e.user_id,
            }));
            this.userList = res.data.data;
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },

    async getHotelDestination() {
      await BookingService.getHotelDestinationsList().then(res => {
        if (resConditionCheck(res.status) && res.data) {
          this.DestinationList = res.data.data.map(e => ({
            label: `${e.name}, ${e.country}`,
            value: e.name,
          }));
        }
      }).catch(error => {
        errorResponseHandler(error)
      })
    },

    disabledRange(date) {
      return date < new Date()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
  .mx-datepicker{
    width: 100% !important;
  }
</style>
